// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, ROUTES, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-powerpack-type-modal',
	CONTENT: 'ac-edit-powerpack-type-modal__content',
};

const AcAddPowerpackTypeModal = ({
	store: { ui, powerpack_types, jeanlutz },
	submit,
}) => {
	const navigate = useNavigate();

	const [fields, setFields] = useState({
		name: '',
		series: '',
		description: '',
		jean_lutz_power_pack_type: null,
		compatible_cu_series: null,
		article_number: '',
	});
	const [errors, setErrors] = useState({
		name: undefined,
		series: undefined,
		description: null,
		jean_lutz_power_pack_type: undefined,
		compatible_cu_series: undefined,
		article_number: undefined,
	});
	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	useEffect(() => {
		jeanlutz.list_powerpacks({ options: true });
	}, []);

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit)
			submit(fields).then((response) => {
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
				if (navigate) {
					navigate(
						ROUTES.POWERPACK_TYPE_DETAIL.path.replace(':id', response.id)
					);
				}
			});
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getTypeInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Type',
			name: 'name',
			value: fields.name,
			callback: handleInputChange,
			validation: handleInputValidation,
			focus: true,
		};
	}, [fields, fields.name]);

	const getSeriesInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Series',
			name: 'series',
			value: fields.series,
			callback: handleInputChange,
			validation: handleInputValidation,
		};
	}, [fields, fields.series]);

	const getExternalTypeReferenceSelectOptions = useMemo(() => {
		const { current_jean_lutz_powerpacks } = jeanlutz;
		let collection = current_jean_lutz_powerpacks;
		let options = [];

		const series = fields.compatible_cu_series;

		if (AcIsSet(collection)) {
			if (AcIsSet(series)) {
				collection = collection.filter((a) => {
					return a.compatible_with_series.indexOf(series) > -1;
				});
			}

			const len = collection.length;
			let n = 0;

			for (n; n < len; n++) {
				const item = collection[n];

				const object = {
					name: item.name,
					value: item.id,
				};

				options.push(object);
			}
		}

		return {
			type: TYPES.TEXT,
			label: 'Interface',
			name: 'jean_lutz_power_pack_type',
			placeholder: !AcIsSet(series)
				? 'Select a control unit series first'
				: 'Select an interface',
			value: fields.jean_lutz_power_pack_type,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: !AcIsSet(series),
			maxOptions: 6,
			options,
		};
	}, [
		jeanlutz.current_jean_lutz_powerpacks,
		fields,
		fields.jean_lutz_power_pack_type,
		fields.compatible_cu_series,
	]);

	const getControlUnitSelectOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Compatible control unit series',
			name: 'compatible_cu_series',
			placeholder: 'Select a control unit series',
			value: fields.compatible_cu_series,
			callback: handleInputChange,
			validation: handleInputValidation,
			options: [
				{ value: 36, name: '36' },
				{ value: 66, name: '66' },
			],
		};
	}, [fields, fields.compatible_cu_series]);

	const getArticleNumberInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Article number',
			name: 'article_number',
			value: fields.article_number,
			callback: handleInputChange,
			validation: handleInputValidation,
			required: true,
		};
	}, [fields, fields.article_number]);

	const getDescriptionInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Description',
			name: 'description',
			value: fields.description,
			callback: handleInputChange,
			validation: handleInputValidation,
			required: false,
		};
	}, [fields, fields.description]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save',
			callback: handleSubmit,
		};
	}, [fields, errors, hasErrors]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getTypeInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getSeriesInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcSelectBox {...getControlUnitSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getArticleNumberInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getDescriptionInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcSelectBox {...getExternalTypeReferenceSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{powerpack_types.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcAddPowerpackTypeModal));
