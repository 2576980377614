// Imports => React
import React, { useEffect, useMemo, memo } from 'react';
import clsx from 'clsx';

const _CLASSES = {
  MAIN: 'ac-drawer',
  HIDDEN: 'ac-drawer--hidden',
};

let classes = null;

const AcDrawer = ({ children, className, visible = false }) => {
  useEffect(() => {}, []);

  const getStyleClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, !visible && _CLASSES.HIDDEN, className);
  }, [className, visible]);

  return <section className={getStyleClassNames}>{children}</section>;
};
export default memo(AcDrawer);
