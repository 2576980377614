import React, { useState, useCallback, useMemo, memo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import clsx from 'clsx';

// Imports => Constants
import { DATETIME_FORMATS, KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet, AcIsNull, AcFormatDate } from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcDatepickerInput from '@atoms/ac-datepicker-input/ac-datepicker-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-date-range-selection-modal',
	CONTENT: 'ac-date-range-selection-modal__content',
	BUTTON: {
		MAIN: 'ac-date-range-selection-modal__button',
		SELECTED: 'ac-date-range-selection-modal__button--selected',
	},
};

const AcDateRangeSelectionModal = ({
	value = { start: new Date(), end: new Date() },
	submit,
	cancel,
}) => {
	const [range, setRange] = useState(null);
	const [selection, setSelection] = useState(value);

	const ranges = [
		{
			id: AcUUID(),
			label: 'Today',
			key: KEYS.TODAY,
		},
		{
			id: AcUUID(),
			label: 'Yesterday',
			key: KEYS.YESTERDAY,
		},
		{
			id: AcUUID(),
			label: 'Last 7 days',
			key: KEYS.LAST_7_DAYS,
		},
		{
			id: AcUUID(),
			label: 'Last 15 days',
			key: KEYS.LAST_15_DAYS,
		},
		{
			id: AcUUID(),
			label: 'Last 30 days',
			key: KEYS.LAST_30_DAYS,
		},

		{
			id: AcUUID(),
			label: 'Last 90 days',
			key: KEYS.LAST_90_DAYS,
		},
	];

	const handleRangeSelection = (event, key) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();

		if (AcIsNull(key)) {
			setRange(null);
			return;
		}

		if (!AcIsSet(key)) return;

		let start_date = null;
		let end_date = null;

		switch (key) {
			case KEYS.TODAY:
				start_date = dayjs().startOf('day').format();
				end_date = dayjs().endOf('day').format();
				break;

			case KEYS.YESTERDAY:
				start_date = dayjs().add(-1, 'day').startOf('day').format();
				end_date = dayjs().add(-1, 'day').endOf('day').format();
				break;

			case KEYS.LAST_7_DAYS:
				start_date = dayjs().add(-7, 'day').startOf('day').format();
				end_date = dayjs().endOf('day').format();
				break;

			case KEYS.LAST_15_DAYS:
				start_date = dayjs().add(-15, 'day').startOf('day').format();
				end_date = dayjs().endOf('day').format();
				break;

			case KEYS.LAST_30_DAYS:
				start_date = dayjs().add(-30, 'day').startOf('day').format();
				end_date = dayjs().endOf('day').format();
				break;

			case KEYS.LAST_90_DAYS:
				start_date = dayjs().add(-90, 'day').startOf('day').format();
				end_date = dayjs().endOf('day').format();
				break;
		}

		if (start_date && end_date) {
			start_date = new Date(start_date);
			end_date = new Date(end_date);

			setSelection({ start: start_date, end: end_date });

			setRange(key);
		}
	};

	const handleInputChange = (event, name, value, type) => {
		let _val = new Date(value);

		switch (name) {
			case 'start':
				setSelection({ start: _val, end: selection.end });
				break;

			case 'end':
				setSelection({ start: selection.start, end: _val });
				break;

			default:
		}

		setRange(null);
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (cancel) cancel();
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit) submit(selection);
	};

	const getRangeSelectButtonClassNames = useCallback(
		(item) => {
			return clsx(
				_CLASSES.BUTTON.MAIN,
				item.key === range && _CLASSES.BUTTON.SELECTED
			);
		},
		[range, selection]
	);

	const getRangeSelectButtonOptions = useCallback(
		(item) => {
			return {
				type: TYPES.BUTTON,
				theme: THEMES.ALPHA,
				title: item.label,
				className: getRangeSelectButtonClassNames(item),
				onClick: (event) => handleRangeSelection(event, item.key),
			};
		},
		[ranges, selection]
	);

	const renderRangeSelectors = useMemo(() => {
		const collection = ranges;
		const len = collection.length;
		let n = 0;
		let result = [];

		const custom = (
			<AcColumn xs={12} key={'custom-range'}>
				<button {...getRangeSelectButtonOptions({ key: null })}>
					<span>Custom range</span>
					<AcRipple size={'small'} simple />
				</button>
			</AcColumn>
		);
		result.push(custom);

		for (n; n < len; n++) {
			const item = collection[n];

			const object = (
				<AcColumn xs={12} key={item.id}>
					<button {...getRangeSelectButtonOptions(item)}>
						<span>{item.label}</span>
						<AcRipple size={'small'} simple />
					</button>
				</AcColumn>
			);

			result.push(object);
		}

		return result;
	}, [ranges, selection]);

	const getStartDateInputOptions = useMemo(() => {
		const end = new Date(selection.end);
		const start = new Date(selection.start);

		return {
			type: TYPES.DATE,
			label: 'Start date',
			placeholder: 'dd/mm/yyyy',
			name: 'start',
			value: start,
			start: start,
			end: end,
			range: 'start',
			maxDate: new Date(),
			callback: handleInputChange,
			inline: true,
		};
	}, [selection, selection.start, selection.end]);

	const getEndDateInputOptions = useMemo(() => {
		const end = new Date(selection.end);
		const start = new Date(selection.start);

		return {
			type: TYPES.DATE,
			label: 'End date',
			placeholder: 'dd/mm/yyyy',
			name: 'end',
			value: end,
			min: start,
			start: start,
			end: end,
			range: 'end',
			maxDate: new Date(),
			callback: handleInputChange,
			inline: true,
		};
	}, [selection, selection.end, selection.start]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled:
				!AcIsSet(selection) ||
				!AcIsSet(selection.start) ||
				!AcIsSet(selection.end),
			title: 'Save',
			callback: handleSubmit,
		};
	}, [selection, selection.start, selection.end, submit]);

	const renderStartDate = useMemo(() => {
		if (!AcIsSet(selection.start))
			return (
				<AcHeading className={'h-margin-top-15 h-text--align-center'} rank={6}>
					From: -
				</AcHeading>
			);

		const formatted = AcFormatDate(
			selection.start,
			null,
			DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR_NO_TIME
		);

		return (
			<AcHeading className={'h-margin-top-15 h-text--align-center'} rank={6}>
				From: {formatted}
			</AcHeading>
		);
	}, [selection, selection.start]);

	const renderEndDate = useMemo(() => {
		if (!AcIsSet(selection.end))
			return (
				<AcHeading className={'h-margin-top-15 h-text--align-center'} rank={6}>
					To: -
				</AcHeading>
			);

		const formatted = AcFormatDate(
			selection.end,
			null,
			DATETIME_FORMATS.RAW_DATETIME_WITH_YEAR_NO_TIME
		);

		return (
			<AcHeading className={'h-margin-top-15 h-text--align-center'} rank={6}>
				To: {formatted}
			</AcHeading>
		);
	}, [selection, selection.end]);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn xs={12} sm={6} md={3}>
								<AcRow>{renderRangeSelectors}</AcRow>
							</AcColumn>

							<AcColumn xs={12} sm={6} md={9}>
								<AcRow>
									<AcColumn xs={12} sm={6}>
										{renderStartDate}
										<AcDatepickerInput {...getStartDateInputOptions} />
									</AcColumn>

									<AcColumn xs={12} sm={6}>
										{renderEndDate}
										<AcDatepickerInput {...getEndDateInputOptions} />
									</AcColumn>
								</AcRow>
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save selection</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>
		</div>
	);
};

export default AcDateRangeSelectionModal;
