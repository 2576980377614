// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import FileSaver from 'file-saver';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Files
import PileListTemplateMonopile from '@assets/files/pilelists/MIQIP_pile_list_template_monopile.xlsx';
import PileListTemplatePinPile from '@assets/files/pilelists/MIQIP_pile_list_template_pin_pile.xlsx';
import PileListTemplateAnchorpile from '@assets/files/pilelists/MIQIP_pile_list_template_anchor_pile.xlsx';
import PileListTemplateOpenEndedpile from '@assets/files/pilelists/MIQIP_pile_list_template_open_ended_pile.xlsx';
import PileListTemplateClosedEndedpile from '@assets/files/pilelists/MIQIP_pile_list_template_closed_ended_pile.xlsx';

// Imports => Constants
import {
	DATETIME_FORMATS,
	KEYS,
	PERMISSIONS,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsEmptyString, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcCheckbox = loadable(() => import('@atoms/ac-checkbox/ac-checkbox.web'));
const AcSelectBox = loadable(() =>
	import('@atoms/ac-select-box/ac-select-box.web')
);
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
	MAIN: 'ac-edit-contract-modal',
	CONTENT: 'ac-edit-contract-modal__content',
};

let delay = null;

const AcDownloadPileListTemplateModal = ({
	store: { ui },
	data,
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	let raw_fields = {
		type: data?.type,
	};
	let raw_errors = {
		type: undefined,
	};

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getTypeSelectBoxOptions = useMemo(() => {
		let options = [
			{ name: 'Monopile', value: 1 },
			{ name: 'Pin pile', value: 2 },
			{ name: 'Anchor pile', value: 3 },
			{ name: 'Open-ended pile', value: 4 },
			{ name: 'Closed-ended pile', value: 5 },
		];

		return {
			type: TYPES.TEXT,
			label: 'Type of pile list',
			name: 'type',
			placeholder: 'Select a pile list type',
			value: fields.type,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: AcIsSet(data?.type),
			maxOptions: 6,
			options,
		};
	}, [fields, fields.type]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getPileListTemplate = useMemo(() => {
		switch (parseInt(fields.type, 10)) {
			case 1: // monopile
				return {
					file: PileListTemplateMonopile,
					filename: 'MIQIP_pile_list_template_monopile.xlsx',
				};
				break;

			case 2: // pin pile
				return {
					file: PileListTemplatePinPile,
					filename: 'MIQIP_pile_list_template_pin_pile.xlsx',
				};
				break;

			case 3: // anchor pile
				return {
					file: PileListTemplateAnchorpile,
					filename: 'MIQIP_pile_list_template_anchor_pile.xlsx',
				};
				break;

			case 4: // open-ended pile
				return {
					file: PileListTemplateOpenEndedpile,
					filename: 'MIQIP_pile_list_template_open_ended_pile.xlsx',
				};
				break;

			case 5: // closed-ended pile
				return {
					file: PileListTemplateClosedEndedpile,
					filename: 'MIQIP_pile_list_template_closed_ended_pile.xlsx',
				};
				break;
		}

		return null;
	}, [fields, fields.type]);

	const getSubmitButtonOptions = useMemo(() => {
		return {
			href: getPileListTemplate?.file,
			type: TYPES.BUTTON,
			theme: THEMES.ALPHA,
			title: 'Download pile list template',
			disabled: !getPileListTemplate?.file,
			callback: async () => {
				await FileSaver.saveAs(
					getPileListTemplate.file,
					getPileListTemplate.filename
				);

				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		};
	}, [fields, fields.type, errors, hasErrors, getPileListTemplate, callback]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid>
					<AcRow>
						<AcColumn>
							<AcRichContent
								className={'h-margin-top-10 h-margin-bottom-0'}
								content={`<p>Select the pile type. The template will contain the right parameter columns.</p>`}
							/>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-10'}>
						<AcColumn xs={12}>
							<AcSelectBox {...getTypeSelectBoxOptions} />
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-20'}>
						<AcColumn
							xxs={12}
							xs={7}
							sm={6}
							className={'h-text--align-left h-flex-v-align-center'}
						>
							<AcButton {...getCancelButtonOptions}>
								<span>Cancel</span>
							</AcButton>
						</AcColumn>

						<AcColumn xxs={12} xs={5} sm={6} className={'h-text--align-right'}>
							<AcButton
								tag="a"
								download="miqip-pile-list-template.xlsx"
								{...getSubmitButtonOptions}
							>
								<span>Confirm</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default withStore(observer(AcDownloadPileListTemplateModal));
