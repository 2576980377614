// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import clsx from 'clsx';

// Imports => Constants
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  SIZES,
  THEMES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcSortBy,
  AcFormatRole,
  AcGenerateBasicPassword,
  AcGenerateAdvancedPassword,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-add-user-modal',
  CONTENT: 'ac-add-user-modal__content',
};

const AcAddUserModal = ({
  store: { ui, users, companies },
  company = null,
  submit,
  callback,
}) => {
  const navigate = useNavigate();
  const { can, cannot, canAssignRoles } = usePermissions();

  let raw_fields = {
    name: '',
    email: '',
    phone: '',
    company_id: AcIsSet(company) && AcIsSet(company.id) ? company.id : '',
    basic_password: AcGenerateBasicPassword(),
    advanced_password: AcGenerateAdvancedPassword(),
    roles: [],
  };
  let raw_errors = {
    name: undefined,
    email: undefined,
    phone: undefined,
    company_id: AcIsSet(company) && AcIsSet(company.id) ? null : undefined,
    basic_password: undefined,
    advanced_password: undefined,
    roles: undefined,
  };

  if (cannot(PERMISSIONS.USER.UPDATE_COMPANY)) {
    delete raw_fields.company_id;
    delete raw_errors.company_id;
  }

  if (cannot(PERMISSIONS.USER.UPDATE_PHONE)) {
    delete raw_fields.phone;
    delete raw_errors.phone;
  }

  if (cannot(PERMISSIONS.BASIC_PASSWORD.UPDATE)) {
    delete raw_fields.basic_password;
    delete raw_errors.basic_password;
  }

  if (cannot(PERMISSIONS.ADVANCED_PASSWORD.UPDATE) && !canAssignRoles()) {
    delete raw_fields.advanced_password;
    delete raw_errors.advanced_password;
  }

  if (!canAssignRoles()) {
    delete raw_fields.roles;
    delete raw_errors.roles;
  }

  const [companyDisabled, setCompanyDisabled] = useState(false);
  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const { hasErrors, handleInputChange, handleInputValidation } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  useEffect(() => {
    if (canAssignRoles()) users.get_roles();
    if (can(PERMISSIONS.USER.UPDATE_COMPANY))
      companies.list({ options: true }, false);
  }, []);

  useEffect(() => {
    if (company && company.id) {
      handleInputValidation('company_id', company.id, true, TYPES.TEXT);
    }
  }, [company]);

  const verifyRolesVsCompany = async () => {
    if (AcIsSet(fields.roles)) {
      if (
        fields.roles.indexOf(ROLES.RENTAL_COORDINATOR) > -1 ||
        fields.roles.indexOf(ROLES.CDE) > -1
      ) {
        const { current_companies_list } = companies;
        const collection = current_companies_list;
        const len = current_companies_list.length;
        let n = 0;
        let result = null;

        for (n; n < len; n++) {
          const item = current_companies_list[n];

          let { name } = item;
          name = name.toUpperCase();

          if (name === KEYS.IHC_IQIP_RENTAL) {
            result = item.id;
            break;
          }
        }

        if (AcIsSet(result)) {
          await handleInputChange(null, 'company_id', result, TYPES.TEXT);
          handleInputValidation('company_id', result, true, TYPES.TEXT);
          setCompanyDisabled(true);
        }
      } else {
        setCompanyDisabled(false);
      }
    }
  };

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();

    if (submit)
      submit(fields).then((response) => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        if (navigate) {
          navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
        }
      });
  };

  const handleRegeneratePassword = (event, type) => {
    if (!type) return;
    if (event && event.preventDefault) event.preventDefault();
    const new_value =
      type === 'basic'
        ? AcGenerateBasicPassword()
        : AcGenerateAdvancedPassword();
    const $input = document.querySelector(`input[name="${type}_password"]`);
    if ($input) {
      $input.value = new_value;
      $input.dispatchEvent(new Event('change'));
      handleInputChange(null, `${type}_password`, new_value, TYPES.TEXT);
    }
  };

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name]);

  const getEmailInputOptions = useMemo(() => {
    return {
      type: TYPES.EMAIL,
      label: 'Email address',
      placeholder: 'name@domain.com',
      name: 'email',
      value: fields.email,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.email]);

  const getPhoneInputOptions = useMemo(() => {
    return {
      type: TYPES.PHONE,
      label: 'Phone number',
      placeholder: 'Phone number',
      name: 'phone',
      required: false,
      value: fields.phone,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.phone]);

  const getCompanySelectOptions = useMemo(() => {
    const { current_companies_list } = companies;
    let options = [];

    if (current_companies_list) {
      const len = current_companies_list.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = current_companies_list[n];

        const object = {
          name: item.name,
          value: item.id,
        };

        options.push(object);
      }
    }

    const value =
      (fields && fields.company_id) || (company && company.id) || null;

    return {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'company_id',
      placeholder: 'Select a company',
      value,
      callback: handleInputChange,
      validation: handleInputValidation,
      disabled: companyDisabled,
      maxOptions: 6,
      options,
    };
  }, [
    companies.current_companies_list,
    companyDisabled,
    company,
    fields,
    fields.company_id,
  ]);

  const renderRolesCheckboxes = useMemo(() => {
    const collection = users.current_roles;
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = collection[n];

      const { id, name } = item;

      const options = {
        type: TYPES.CHECKBOX,
        name: 'roles',
        id: `ac-roles-checkbox-${id}`,
        value: name,
        callback: async (event, name, value, type, checked) => {
          await handleInputChange(event, name, value, type, checked);
          verifyRolesVsCompany();
        },
        validation: handleInputValidation,
        checked: fields.roles && fields.roles.indexOf(name) > -1,
      };

      const label = AcFormatRole(name);

      const object = (
        <AcColumn xs={12} sm={6} key={`ac-checkbox-${id}`}>
          <AcCheckbox {...options}>
            <span
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          </AcCheckbox>
        </AcColumn>
      );

      result.push(object);
    }

    return result;
  }, [users.current_roles, fields, fields.roles]);

  const getBasicPasswordInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Basic password',
      name: 'basic_password',
      value: fields.basic_password,
      callback: handleInputChange,
      validation: handleInputValidation,
      readonly: true,
      action: (
        <button
          type={TYPES.BUTTON}
          onClick={(event) => handleRegeneratePassword(event, 'basic')}
        >
          <AcIcon icon={ICONS.REFRESH} />
          <span>Regenerate</span>
          <AcRipple theme={THEMES.DARK} size={SIZES.SMALL} simple />
        </button>
      ),
    };
  }, [fields, fields.basic_password, handleRegeneratePassword]);

  const getAdvancedPasswordInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Advanced password',
      name: 'advanced_password',
      value: fields.advanced_password,
      callback: handleInputChange,
      validation: handleInputValidation,
      readonly: true,
      action: (
        <button
          type={TYPES.BUTTON}
          onClick={(event) => handleRegeneratePassword(event, 'advanced')}
        >
          <AcIcon icon={ICONS.REFRESH} />
          <span>Regenerate</span>
          <AcRipple theme={THEMES.DARK} size={SIZES.SMALL} simple />
        </button>
      ),
    };
  }, [fields, fields.advanced_password, handleRegeneratePassword]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: errors && hasErrors,
      title: 'Save',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <form method={'post'} onSubmit={handleSubmit}>
          <AcContainer fluid>
            <AcRow>
              <AcColumn>
                <AcTextInput {...getNameInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getEmailInputOptions} />
              </AcColumn>
            </AcRow>

            <AcCheckPermissions allowed={PERMISSIONS.USER.UPDATE_PHONE}>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getPhoneInputOptions} />
                </AcColumn>
              </AcRow>
            </AcCheckPermissions>

            <AcCheckPermissions allowed={PERMISSIONS.USER.UPDATE_COMPANY}>
              <AcRow>
                <AcColumn>
                  <AcSelectBox {...getCompanySelectOptions} />
                </AcColumn>
              </AcRow>
            </AcCheckPermissions>

            <AcCheckPermissions allowed={canAssignRoles()}>
              <AcRow>
                <AcColumn xs={12}>
                  <AcHeading
                    rank={5}
                    className={'h-margin-top-30 h-margin-bottom-10'}
                  >
                    User role(s)
                  </AcHeading>
                </AcColumn>
                {renderRolesCheckboxes}
              </AcRow>
            </AcCheckPermissions>

            <AcCheckPermissions allowed={PERMISSIONS.BASIC_PASSWORD.UPDATE}>
              <Fade
                duration={200}
                collapse
                reverse={
                  !(
                    fields &&
                    fields.roles &&
                    fields.roles.indexOf(ROLES.OPERATOR) > -1
                  )
                }
              >
                <AcRow>
                  <AcColumn xs={12}>
                    <AcHeading
                      rank={5}
                      className={'h-margin-top-30 h-margin-bottom--10'}
                    >
                      Control Panel Credentials
                    </AcHeading>
                  </AcColumn>

                  <AcColumn className={'h-margin-top-20'}>
                    <AcTextInput {...getBasicPasswordInputOptions} />
                  </AcColumn>
                </AcRow>
              </Fade>
            </AcCheckPermissions>

            <AcCheckPermissions
              allowed={PERMISSIONS.ADVANCED_PASSWORD.UPDATE && canAssignRoles()}
            >
              <Fade
                duration={200}
                collapse
                reverse={
                  !(
                    fields &&
                    fields.roles &&
                    fields.roles.indexOf(ROLES.SERVICE_ENGINEER) > -1
                  )
                }
              >
                <AcRow>
                  <AcColumn xs={12}>
                    <AcHeading
                      rank={5}
                      className={'h-margin-top-30 h-margin-bottom--10'}
                    >
                      Control Panel Credentials
                    </AcHeading>
                  </AcColumn>

                  <AcColumn className={'h-margin-top-20'}>
                    <AcTextInput {...getAdvancedPasswordInputOptions} />
                  </AcColumn>
                </AcRow>
              </Fade>
            </AcCheckPermissions>

            <AcRow className={'h-margin-top-20'}>
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={5}
                sm={6}
                className={'h-text--align-right'}
              >
                <AcButton {...getSubmitButtonOptions}>
                  <span>Save</span>
                </AcButton>
              </AcColumn>
            </AcRow>
          </AcContainer>
        </form>
      </div>
      {users.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcAddUserModal));
